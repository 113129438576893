import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
const horizontal = 50;
const verticalBottom = 25;
const verticalTop = 50;

const MEDIA = "../../assets/imgs/";

// ================================================================================
// Welcome toast message ...
function welcomeOptions(text, nextCallback = null) {
  return {
    avatar: MEDIA + "schoollogo.png",
    text: `${text}`,
    className: "toastWelcome",
    duration: 8000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "top",
    position: "center",
    stopOnFocus: false, // Prevents dismissing of toast on hover
    offset: {
      //x: horizontal, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: 70, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    style: {
      background: "#363636"
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Logout toast message ...
function LogoutOptions(text, nextCallback = null) {
  return {
    avatar: MEDIA + "goodbye.png",
    text: `Goodbye, ${text}`,
    className: "toastGoodbye",
    duration: 2000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "top",
    position: "center",
    stopOnFocus: false, // Prevents dismissing of toast on hover
    offset: {
      //x: horizontal, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: 70, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    style: {
      background: "linear-gradient(to right, #ffc371, #ff5f6d)",
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Success toast message ...
function successOptions(text, nextCallback = null) {
  return {
    avatar: MEDIA + "smile.png",
    text: text,
    duration: 2000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #41ad49, #96c93d)",
    },
    offset: {
      //x: horizontal,   // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: verticalBottom, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Warning toast message ...
function warningOptions(text, nextCallback = null) {
  return {
    //avatar: '',
    text: text,
    duration: 2000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      background: "#0f3443",
    },
    offset: {
      //x: horizontal,   // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: verticalBottom, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Information toast message ...
function informationOptions(text, nextCallback = null) {
  return {
    text: text,
    duration: 2000,
    stopOnFocus: false,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    offset: {
      //x: horizontal,   // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: verticalBottom, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Error toast message ...
function errorOptions(text, nextCallback = null) {
  return {
    avatar: MEDIA + "sad.png",
    text: text,
    duration: 3000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #ff5f6d, #ffc371)",
    },
    offset: {
      //x: horizontal,    // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: verticalBottom, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Maximum attempts reached! ...
function attemptsReachedOptions(text, nextCallback = null) {
  return {
    avatar: MEDIA + "goodbye.png",
    text: text,
    className: "toastGoodbye",
    duration: 2000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "top",
    position: "center",
    stopOnFocus: false, // Prevents dismissing of toast on hover
    offset: {
      //x: horizontal, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: 70, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    style: {
      //background: "linear-gradient(to right, #ffc371, #ff5f6d)"
      background: "#1c1c1c",
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Top Warning toast message ...
function topWarningOptions(text, nextCallback = null) {
  return {
    //avatar: '',
    text: text,
    duration: 2000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      background: "#0f3443",
    },
    offset: {
      //x: horizontal,   // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: verticalBottom, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// Top Success toast message ...
function topSuccessOptions(text, nextCallback = null) {
  return {
    //avatar: '',
    text: text,
    duration: 2000,
    //destination: "https://github.com/apvarun/toastify-js",
    newWindow: true,
    //close: true,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: false, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #41ad49, #96c93d)",
    },
    offset: {
      //x: horizontal,   // horizontal axis - can be a number or a string indicating unity. eg: '2em'
      y: verticalBottom, // vertical axis - can be a number or a string indicating unity. eg: '2em'
    },
    callback: function () {
      if (nextCallback) {
        nextCallback();
        Toastify.reposition();
      }
    },
    onClick: function () {}, // Callback after click
  };
}
// ================================================================================
const Toastinit = (textcontent, options, nextCallback) => {
  return new Promise((myResolve, myReject) => {
    try {
      Toastify(options(textcontent, nextCallback)).showToast();
      myResolve();
    } catch (err) {
      // when error ...
      myReject(err);
    }
  });
};
// ================================================================================
export const ToastifyShow = (
  type = "",
  textcontent = "",
  nextCallback = null
) => {
  return new Promise(function () {
    switch (type) {
      case "welcome":
        return Toastinit(textcontent, welcomeOptions, nextCallback); // Welcome message
      case "success":
        return Toastinit(textcontent, successOptions, nextCallback); // Success message
      case "warning":
        return Toastinit(textcontent, warningOptions, nextCallback); // Warning message
      case "information":
        return Toastinit(textcontent, informationOptions, nextCallback); // Information message
      case "error":
        return Toastinit(textcontent, errorOptions, nextCallback); // Error message
      case "logout":
        return Toastinit(textcontent, LogoutOptions, nextCallback); // Logout message
      case "attempts-reached":
        return Toastinit(textcontent, attemptsReachedOptions, nextCallback); // Maximum attempts reached message
      case "topwarning":
        return Toastinit(textcontent, topWarningOptions, nextCallback); // Topwarning message
      case "topsuccess":
        return Toastinit(textcontent, topSuccessOptions, nextCallback); // topsuccess message
      default:
        return Promise.reject("transaction did not occur.");
    }
  });
};

/**
 * exm:          -type-     -text content-        -next callback-
 *
 * ToastifyShow('success', 'hello world:)', () => alert("hello world:));
 */
