import { ToastifyShow } from "../../Actions";

function ToastifyShowRun(type = "", text = "", callback = null) {
  ToastifyShow(type, text, callback);
}

let captchaContainer = document.getElementById("recover-captcha");
let captchaReload = document.getElementById("recover-reload-captcha");

let emailInput = document.getElementById("recover-email-input");
let CaptchaInput = document.getElementById("recover-captcha-input");
let recoverbtn = document.getElementById("recover-btn");

const ForgotPassword = {
  /////////////////////////////////////////////////////////////////
  init: function () {
    ForgotPassword.default();
    /*---start enter login---*/
    document.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        ForgotPassword.create();
      }
    });

    recoverbtn.onclick = function () {
      ForgotPassword.create();
    };

    captchaReload.onclick = function () {
      ForgotPassword.createCaptcha();
    };
  },
  /////////////////////////////////////////////////////////////////
  isValidEmail: function (email) {
    var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  },
  /////////////////////////////////////////////////////////////////
  decryptData: function (encryptedData) {
    encryptedData = atob(encryptedData);
    return encryptedData.split("").reverse().join("");
  },
  /////////////////////////////////////////////////////////////////
  createCaptcha: function () {
    //show
    CaptchaInput.value = "";
    //clear the contents of captcha div first
    captchaContainer.innerHTML = null;

    fetch("/api/Home/captcha")
      .then((response) => response.text())
      .then((base64String) => {
        document.getElementById("robobaxSpinner").style.display = "none";

        const imgUrl = `data:image/png;base64, ${base64String}`;
        const imgElement = document.createElement("img");
        imgElement.src = imgUrl;
        captchaContainer.appendChild(imgElement);
      })
      .catch((error) => console.error(error));
  },
  /////////////////////////////////////////////////////////////////
  create: async function () {
    var email = emailInput.value;
    var captcha = CaptchaInput.value;

    if (!email.replace(/\s+/g, "")) {
      ForgotPassword.wrongAlert(
        Functions.systemMessages["lg_variable_fill_empty_fields"]
      );
      return;
    }

    if (!ForgotPassword.isValidEmail(email)) {
      ForgotPassword.wrongAlert(
        Functions.systemMessages["lg_variable_invalid_email"]
      );
      return;
    }

    if (!captcha.replace(/\s+/g, "")) {
      ForgotPassword.wrongAlert(
        Functions.systemMessages["lg_variable_wrong_captcha"]
      );
      ForgotPassword.createCaptcha();
      return;
    }

    Functions.Field.email = email;
    Functions.Field.captchacode = captcha;

    ForgotPassword.performCreateProccess();
  },
  /////////////////////////////////////////////////////////////////
  performCreateProccess: function () {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-From-Forgotpassword", "true");

    document.getElementById("robobaxSpinner").style.display = "block";

    fetch("/api/Home/forgotpassword", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        Email: Functions.Field.email,
        Captcha: Functions.Field.captchacode,
        CountryCode: Functions.systemMessages["lg_variable_lang_code"], // system message gelecek
        IpAddress: "",
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          throw new Error(JSON.stringify(data));
        }
      })
      .then((response) => {
        document.getElementById("robobaxSpinner").style.display = "none";

        const res = JSON.parse(response[0]["user_notlogin"]);
        if (res["Status"] === 1) {
          ForgotPassword.default();
          ForgotPassword.successAlert(
            `${Functions.systemMessages["lg_variable_password_received_request_to_reset1"]} <strong>${res["Email"]}</strong> ${Functions.systemMessages["lg_variable_password_received_request_to_reset2"]}`
          );
          return;
        }

        if (res["Status"] === 0 && res["ErrCode"] === 2) {
          ForgotPassword.createCaptcha();
          ForgotPassword.wrongAlert(
            Functions.systemMessages["lg_variable_user_not_found"]
          );
          return;
        }
      })
      .catch((errorObject) => {
        console.error("Error: ", errorObject);

        ForgotPassword.Unauthorized(errorObject)
          ? (ForgotPassword.wrongAlert(
              Functions.systemMessages["lg_variable_wrong_captcha"]
            ),
            ForgotPassword.createCaptcha())
          : ToastifyShowRun("topwarning", errorObject.message);
      });
  },
  /////////////////////////////////////////////////////////////////
  wrongAlert: function (text) {
    document.getElementById("recover-alert-success").style.display = "none";
    const alert = document.getElementById("recover-alert-warning");
    document.getElementById("recover-alert-warningtxt").innerHTML = text;
    alert.style.display = "block";
  },
  /////////////////////////////////////////////////////////////////
  successAlert: function (text) {
    document.getElementById("recover-alert-warning").style.display = "none";
    const alert = document.getElementById("recover-alert-success");
    document.getElementById("recover-alert-successtxt").innerHTML = text;
    alert.style.display = "block";
  },
  /////////////////////////////////////////////////////////////////
  default: function () {
    emailInput.value = "";
    document.getElementById("recover-alert-warning").style.display = "none";
    document.getElementById("recover-alert-success").style.display = "none";
    document.getElementById("robobaxSpinner").style.display = "block";

    ForgotPassword.createCaptcha();
  },
  /////////////////////////////////////////////////////////////////
  Unauthorized: function (errorObject, key = "captchawrong") {
    try {
      return JSON.parse(errorObject.message).msg.includes(key);
    } catch (err) {
      return false;
    }
  },
};

const Functions = {
  systemMessages: null,
  Field: {
    email: "",
    captchacode: "",
  },
  ForgotPasswordInit: function () {
    ForgotPassword.init();
  },
};

const ForgotPasswordController = (systemMessages) => {
  Functions.systemMessages = systemMessages;
  Functions.ForgotPasswordInit();
};

export { ForgotPasswordController };
