import { ToastifyShow } from "../../Actions";

function ToastifyShowRun(type = "", text = "", callback = null) {
  ToastifyShow(type, text, callback);
}

let captchaContainer = document.getElementById("register-captcha");
let captchaReload = document.getElementById("register-reload-captcha");

let emailInput = document.getElementById("register-email-input");
let usernameInput = document.getElementById("register-username-input");
let PasswordInput = document.getElementById("register-password-input");
let CaptchaInput = document.getElementById("register-captcha-input");
let registerbtn = document.getElementById("register-btn");
let policyCheck = document.getElementById("policyCheck");

let toast = document.getElementById("register-password-passwordcheck");
let generatePasswordBtn = document.querySelectorAll(".generate-password");

const Register = {
  /////////////////////////////////////////////////////////////////
  init: function () {
    Register.default();
    /*---start enter login---*/
    document.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        Register.register();
      }
    });

    registerbtn.onclick = function () {
      Register.register();
    };

    captchaReload.onclick = function () {
      Register.createCaptcha();
    };

    PasswordInput.addEventListener("focus", function () {
      Register.CheckInputControl(PasswordInput.value);
      toast.classList.add("show");
    });

    PasswordInput.addEventListener("blur", function () {
      toast.classList.remove("show");
    });

    PasswordInput.addEventListener("input", () => {
      Register.CheckInputControl(PasswordInput.value);
    });

    generatePasswordBtn[0].addEventListener("click", () => {
      PasswordInput.value = Register.generateValidPassword();
    });
  },
  /////////////////////////////////////////////////////////////////
  generateValidPassword: function () {
    let newPassword = "";
    do {
      newPassword = Register.generatePassword();
    } while (!Register.validatePassword(newPassword));
    return newPassword;
  },
  /////////////////////////////////////////////////////////////////
  generatePassword: function () {
    const length = 12;
    const charset = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$%^&*`;
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  },
  /////////////////////////////////////////////////////////////////
  validatePassword: function (password) {
    const passwordPattern =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\d\s:])([^\s]){6,20}$/;
    return passwordPattern.test(password);
  },
  /////////////////////////////////////////////////////////////////
  isValidEmail: function (email) {
    var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  },
  /////////////////////////////////////////////////////////////////
  CheckInputControl: function (password, rootKey = "password") {
    // Check length
    if (password.length >= 6 && password.length <= 20) {
      Register.setRequirementComplete(rootKey + "LengthPasswordCheck");
    } else {
      Register.setRequirementIncomplete(rootKey + "LengthPasswordCheck");
    }

    // Check lowercase letters
    if (password.match(/[a-z]/)) {
      Register.setRequirementComplete(rootKey + "LowercasePasswordCheck");
    } else {
      Register.setRequirementIncomplete(rootKey + "LowercasePasswordCheck");
    }

    // Check uppercase letters
    if (password.match(/[A-Z]/)) {
      Register.setRequirementComplete(rootKey + "UppercasePasswordCheck");
    } else {
      Register.setRequirementIncomplete(rootKey + "UppercasePasswordCheck");
    }

    // Check special characters
    if (password.match(/[!#$%^&*+.,]/)) {
      Register.setRequirementComplete(rootKey + "SpecialPasswordCheck");
    } else {
      Register.setRequirementIncomplete(rootKey + "SpecialPasswordCheck");
    }

    // Check numbers
    if (password.match(/[0-9]/)) {
      Register.setRequirementComplete(rootKey + "NumberPasswordCheck");
    } else {
      Register.setRequirementIncomplete(rootKey + "NumberPasswordCheck");
    }

    // Update progress bar
    Register.updateProgressBar(password);
  },
  /////////////////////////////////////////////////////////////////
  setRequirementComplete: function (requirement) {
    const requirementElement = document.getElementById(requirement);
    requirementElement.classList.remove("incomplete");
    requirementElement.classList.add("complete");
    requirementElement.querySelector(".icons").classList.remove("bxs-x-circle");
    requirementElement
      .querySelector(".icons")
      .classList.add("bxs-check-circle");
  },
  /////////////////////////////////////////////////////////////////
  setRequirementIncomplete: function (requirement) {
    const requirementElement = document.getElementById(requirement);
    requirementElement.classList.remove("complete");
    requirementElement.classList.add("incomplete");
    requirementElement
      .querySelector(".icons")
      .classList.remove("bxs-check-circle");
    requirementElement.querySelector(".icons").classList.add("bxs-x-circle");
  },
  /////////////////////////////////////////////////////////////////
  updateProgressBar: function (password, rootKey = "password") {
    const bar = document.getElementById(rootKey + "ProgressBar");
    let strength = 0;
    if (password.length >= 6 && password.length <= 20) {
      strength += 1;
    }
    if (password.match(/[a-z]/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]/)) {
      strength += 1;
    }
    if (password.match(/[0-9]/)) {
      strength += 1;
    }
    if (password.match(/[!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
      strength += 1;
    }

    bar.classList.remove("weak", "medium", "strong");
    if (strength === 1 || strength === 2) {
      bar.classList.add("weak");
      bar.style.width = "33%";
    } else if (strength === 4 || strength === 3) {
      bar.classList.add("medium");
      bar.style.width = "66%";
    } else if (strength === 5) {
      bar.classList.add("strong");
      bar.style.width = "100%";
    } else {
      bar.style.width = "0%";
    }
  },
  /////////////////////////////////////////////////////////////////
  decryptData: function (encryptedData) {
    encryptedData = atob(encryptedData);
    return encryptedData.split("").reverse().join("");
  },
  /////////////////////////////////////////////////////////////////
  createCaptcha: function () {
    //show
    CaptchaInput.value = "";
    //clear the contents of captcha div first
    captchaContainer.innerHTML = null;

    fetch("/api/Home/captcha")
      .then((response) => response.text())
      .then((base64String) => {
        document.getElementById("robobaxSpinner").style.display = "none";

        const imgUrl = `data:image/png;base64, ${base64String}`;
        const imgElement = document.createElement("img");
        imgElement.src = imgUrl;
        captchaContainer.appendChild(imgElement);
      })
      .catch((error) => console.error(error));
  },
  /////////////////////////////////////////////////////////////////
  register: async function () {
    var email = emailInput.value;
    var username = usernameInput.value;
    var password = PasswordInput.value;
    var captcha = CaptchaInput.value;

    if (
      !email.replace(/\s+/g, "") ||
      !username.replace(/\s+/g, "") ||
      !password.replace(/\s+/g, "")
    ) {
      Register.wrongAlert(
        Functions.systemMessages["lg_variable_fill_empty_fields"]
      );
      return;
    }

    if (!Register.isValidEmail(email)) {
      Register.wrongAlert(
        Functions.systemMessages["lg_variable_invalid_email"]
      );
      return;
    }

    if (!Register.validatePassword(password)) {
      Register.wrongAlert(
        Functions.systemMessages["lg_variable_invalid_password"]
      );
      return;
    }

    if (!policyCheck.checked) {
      Register.wrongAlert(
        Functions.systemMessages["lg_variable_ratify_the_convention"]
      );
      return;
    }

    if (!captcha.replace(/\s+/g, "")) {
      Register.wrongAlert(
        Functions.systemMessages["lg_variable_wrong_captcha"]
      );
      Register.createCaptcha();
      return;
    }

    Functions.Field.email = email;
    Functions.Field.username = username;
    Functions.Field.password = password;
    Functions.Field.captchacode = captcha;

    Register.performRegisterProccess();
  },
  /////////////////////////////////////////////////////////////////
  performRegisterProccess: function () {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-From-Register", "true");

    document.getElementById("robobaxSpinner").style.display = "block";

    fetch("/api/Home/register", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        Username: Functions.Field.username,
        Email: Functions.Field.email,
        Password: Functions.Field.password,
        Captcha: Functions.Field.captchacode,
        Birthday: "",
        CountryCode: Functions.systemMessages["lg_variable_lang_code"], // system message gelecek
        IpAddress: "",
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          throw new Error(JSON.stringify(data));
        }
      })
      .then((response) => {
        document.getElementById("robobaxSpinner").style.display = "none";

        const res = JSON.parse(response[0]["user_notlogin"]);
        if (res["Status"] === 1) {
          Register.default();
          Register.successAlert(
            `${Functions.systemMessages["lg_variable_success_create_account1"]} <strong>${res["Email"]}</strong> ${Functions.systemMessages["lg_variable_success_create_account2"]}`
          );
          return;
        }

        if (res["Status"] === 0 && res["ErrCode"] === 2) {
          Register.createCaptcha();
          Register.wrongAlert(
            Functions.systemMessages["lg_variable_email_address_already"]
          );
          return;
        }

        if (res["Status"] === 0 && res["ErrCode"] === 3) {
          Register.createCaptcha();
          Register.wrongAlert(
            Functions.systemMessages["lg_variable_username_already"]
          );
          return;
        }
      })
      .catch((errorObject) => {
        console.error("Error: ", errorObject);

        Register.Unauthorized(errorObject)
          ? (Register.wrongAlert(
              Functions.systemMessages["lg_variable_wrong_captcha"]
            ),
            Register.createCaptcha())
          : ToastifyShowRun("topwarning", errorObject.message);
      });
  },
  /////////////////////////////////////////////////////////////////
  wrongAlert: function (text) {
    document.getElementById("register-alert-success").style.display = "none";
    const alert = document.getElementById("register-alert-warning");
    document.getElementById("register-alert-warningtxt").innerHTML = text;
    alert.style.display = "block";
  },
  /////////////////////////////////////////////////////////////////
  successAlert: function (text) {
    document.getElementById("register-alert-warning").style.display = "none";
    const alert = document.getElementById("register-alert-success");
    document.getElementById("register-alert-successtxt").innerHTML = text;
    alert.style.display = "block";
  },
  /////////////////////////////////////////////////////////////////
  default: function () {
    emailInput.value = "";
    usernameInput.value = "";
    PasswordInput.value = "";
    policyCheck.checked = false;
    document.getElementById("register-alert-warning").style.display = "none";
    document.getElementById("register-alert-success").style.display = "none";
    document.getElementById("robobaxSpinner").style.display = "block";

    Register.createCaptcha();
  },
  /////////////////////////////////////////////////////////////////
  Unauthorized: function (errorObject, key = "captchawrong") {
    try {
      return JSON.parse(errorObject.message).msg.includes(key);
    } catch (err) {
      return false;
    }
  },
};

const Functions = {
  systemMessages: null,
  Field: {
    email: "",
    username: "",
    password: "",
    captchacode: "",
  },
  RegisterInit: function () {
    Register.init();
  },
};

const RegisterController = (systemMessages) => {
  Functions.systemMessages = systemMessages;
  Functions.RegisterInit();
};

export { RegisterController };
