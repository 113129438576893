const serviceState = {
  token: "",
};
// ----------------------------------------------------------------------------------------------------
const Initialization = (
  OBJ_,
  SuccessOperation,
  FailedOperation,
  Progress = false
) => {
  $.support.cors = true;
  var URL_ = "http://localhost:60024/";
  var myVar = null;
  var Progressinner = null;
  $.ajax({
    type: "POST",
    timeout: 300000, // 5 minutes timeout time (in milliseconds).
    contentType: "application/json",
    url: URL_,
    data: JSON.stringify(OBJ_),
    beforeSend: function () {
      /*var xhr = new window.XMLHttpRequest();
            //Upload progress
            xhr.upload.addEventListener("progress", function(evt){
                if (evt.lengthComputable) {  
                var percentComplete = evt.loaded / evt.total;
                console.log(percentComplete)
                //Do something with upload progress
                }
            }, false); 
            //Download progress
            xhr.addEventListener("progress", function(evt){
                if (evt.lengthComputable) {  
                var percentComplete = evt.loaded / evt.total;
                console.log(percentComplete)
                //Do something with download progress
                }
            }, false);*/
      if (!Progress) {
        return;
      }
      Progressinner = document.getElementById("loadProgressText");
      var start_time = new Date().getTime();
      myVar = setInterval(myTimer, 750);
      function myTimer() {
        var percentComplete = (new Date().getTime() - start_time) / 100;
        percentComplete = percentComplete.toFixed();
        if (percentComplete < 100) {
          Progressinner.innerText = "Building " + percentComplete + "%";
        }
      }
    },
    complete: function () {
      // Handle the complete event ...
      if (!Progress) {
        return;
      }
      clearInterval(myVar);
      if (Progressinner) Progressinner.innerText = "Building 100%";
    },
    success: function (response) {
      //
      //console.log(response);
      SuccessOperation(response);
    },
    failure: function (response) {
      //
      console.error(response);
      FailedOperation();
    },
    error: function (response) {
      //
      console.error(response);
      FailedOperation();
    },
  });
};

//var ip = require('ip');
// ----------------------------------------------------------------------------------------------------
const GetEndPoint = (ActionName) => {
  switch (ActionName) {
    case "INDICATORS":
      return "/api/Home/Indicators";

    case "CUSTOMER_INDICATORS":
      return "/api/Home/CustomerIndicators";

    // Customer verification Service ...
    case "CUSTOMER_ACCOUNT_VERIFIED":
      return "/api/Home/CustomerAccountVerified";
    // Customer forgot password Service ...
    case "CUSTOMER_FORGOT_PASSWORD":
      return "/api/Home/CustomerForgotPassword";
    // Customer forgot username Service ...
    case "CUSTOMER_FORGOT_USERNAME":
      return "/api/Home/CustomerForgotUsername";
    // Customer Registration Service ...
    case "CUSTOMER_REGISTER":
      return "/api/Home/CustomerRegistration";
    // Customer Send Email Registration Verification Service ...
    case "CUSTOMER_SEND_EMAIL":
      return "/api/Home/SendEmail";
    // Customer Login Service ...
    case "CUSTOMER_LOGIN":
      return "/api/Home/CustomerLogin";
    // Customer get profile Service ...
    case "CUSTOMER_GET_PROFILE":
      return "/api/Home/CustomerGetProfile";
    // Customer update profile Service ...
    case "CUSTOMER_UPDATE_PROFILE":
      return "/api/Home/CustomerUpdateProfile";
    // Customer Change password Service ...
    case "CUSTOMER_CHANGE_PASSWORD":
      return "/api/Home/CustomerChangePassword";
    // Customer Create Projects Service ...
    case "CREATE_PROJECT":
      return "/api/Home/CreateProject";
    // Customer Example Create Projects Service ...
    case "EXAMPLE_CREATE_PROJECT":
      return "/api/Home/ExampleCreateProject";
    // Customer Share Create Projects Service ...
    case "SHARE_CREATE_PROJECT":
      return "/api/Home/ShareCreateProject";
    // Customer Get Share Projects Service ...
    case "GET_SHARE_PROJECT":
      return "/api/Home/GetShareProject";
    // Customer Save Projects Service ...
    case "SAVE_PROJECT":
      return "/api/Home/SaveProject";
    // Customer SaveAs Projects Service ...
    case "SAVE_AS_PROJECT":
      return "/api/Home/SaveAsProject";
    // Customer Project Name Control Service ...
    case "PROJECT_NAME_CONTROL":
      return "/api/Home/ProjectNameControl";
    // Customer Get Projects Service ...
    case "GET_PROJECTS":
      return "/api/Home/GetProjects";
    // Customer Get Project with detail Service ...
    case "GET_PROJECT_WITH_DETAIL":
      return "/api/Home/GetProjectWithDetail";
    // Customer Multi Deleted Projects Service ...
    case "MULTI_DELETE_PROJECTS":
      return "/api/Home/DeleteProjects";
    // Customer Rename Projects Service ...
    case "RENAME_PROJECT":
      return "/api/Home/RenameProject";
    // Customer Get Examples Projects Service ...

    case "GET_EXAMPLE_PROJECTS":
      return "/api/Home/GetExampleProjects";

    // Customer Send Feedback Projects Service ...
    case "CUSTOMER_SEND_FEEDBACK":
      return "/api/Home/CustomerSendFeedback";
    // Customer Upload wifi generation match Service ...
    case "CUSTOMER_WIFI_GENERATION_MATCH":
      return "/api/Home/WifiUploadGenerationMatch";
    // Customer Upload wifi get match Service ...
    case "CUSTOMER_WIFI_GET_MATCH":
      return "/api/Home/WifiUploadGetMatch";

    // Customer Shop Address
    case "CUSTOMER_SHOP_ADDRESS":
      return "/api/Home/CustomerShopAddress";

    // Customer Set Shop Orders
    case "CUSTOMER_SET_SHOP_ORDERS":
      return "/api/Home/CustomerSetOrders";

    // Customer Get Shop Orders
    case "CUSTOMER_GET_SHOP_ORDERS":
      return "/api/Home/CustomerShopOrders";
  }
};
// ----------------------------------------------------------------------------------------------------
const GetParseData = (ActionName, response) => {
  try {
    switch (ActionName) {
      case "INDICATORS":
        return JSON.parse(response[0]["indicators"]);

      case "CUSTOMER_INDICATORS":
        return JSON.parse(response[0]["customer_indicators"]);

      // Customer verification Service ...
      case "CUSTOMER_ACCOUNT_VERIFIED":
        return JSON.parse(response[0]["account_verified"]);
      // Customer forgot password Service ...
      case "CUSTOMER_FORGOT_PASSWORD":
        return JSON.parse(response[0]["forgot_password"]);
      // Customer forgot username Service ...
      case "CUSTOMER_FORGOT_USERNAME":
        return JSON.parse(response[0]["forgot_username"]);
      // Customer Registration Service ...
      case "CUSTOMER_REGISTER":
        return JSON.parse(response[0]["register_customer"]);
      // Customer Send Email Registration Verification Service ...
      case "CUSTOMER_SEND_EMAIL":
        return JSON.parse(response);
      // Customer Login Service ...
      case "CUSTOMER_LOGIN":
        return JSON.parse(response[0]["login_customer"]);
      // Customer get profile Service ...
      case "CUSTOMER_GET_PROFILE":
        return JSON.parse(response[0]["get_profile_customer"]);
      // Customer update profile Service ...
      case "CUSTOMER_UPDATE_PROFILE":
        return JSON.parse(response[0]["update_profile_customer"]);
      // Customer Change password Service ...
      case "CUSTOMER_CHANGE_PASSWORD":
        return JSON.parse(response[0]["change_password_customer"]);
      // Customer Create Projects Service ...
      case "CREATE_PROJECT":
        return JSON.parse(response[0]["create_projects"]);
      // Customer Example Create Projects Service ...
      case "EXAMPLE_CREATE_PROJECT":
        return JSON.parse(response[0]["create_projects"]);
      // Customer Share Create Projects Service ...
      case "SHARE_CREATE_PROJECT":
        return JSON.parse(response[0]["create_share_projects"]);
      // Customer Get Share Projects Service ...
      case "GET_SHARE_PROJECT":
        return JSON.parse(response[0]["get_shared_projects"]);
      // Customer Save Projects Service ...
      case "SAVE_PROJECT":
        return JSON.parse(response[0]["save_projects"]);
      // Customer SaveAs Projects Service ...
      case "SAVE_AS_PROJECT":
        return JSON.parse(response[0]["save_as_projects"]);
      // Customer Project Name Control Service ...
      case "PROJECT_NAME_CONTROL":
        return JSON.parse(response[0]["project_name_control"]);
      // Customer Get Projects Service ...
      case "GET_PROJECTS":
        return JSON.parse(response[0]["get_projects"]);
      // Customer Get Project with detail Service ...
      case "GET_PROJECT_WITH_DETAIL":
        return JSON.parse(response[0]["get_project_with_detail"]);
      // Customer Multi Delete Projects Service ...
      case "MULTI_DELETE_PROJECTS":
        return JSON.parse(response[0]["delete_project"]);
      // Customer Rename Projects Service ...
      case "RENAME_PROJECT":
        return JSON.parse(response[0]["rename_project"]);
      // Customer Get Examples Projects Service ...

      case "GET_EXAMPLE_PROJECTS":
        return JSON.parse(response[0]["customer_example_getproject"]);

      // Customer Send Feedback Projects Service ...
      case "CUSTOMER_SEND_FEEDBACK":
        return JSON.parse(response[0]["customer_send_feedback"]);
      // Customer Upload wifi generation match Service ...
      case "CUSTOMER_WIFI_GENERATION_MATCH":
        return JSON.parse(response[0]["wifiUpload_generation_match"]);
      // Customer Upload wifi get match Service ...
      case "CUSTOMER_WIFI_GET_MATCH":
        return JSON.parse(response[0]["wifiUpload_get_match"]);

      // Customer Shop Address
      case "CUSTOMER_SHOP_ADDRESS":
        return JSON.parse(response[0]["customer_shop_address"]);

      // Customer Set Shop Orders
      case "CUSTOMER_SET_SHOP_ORDERS":
        return JSON.parse(response[0]["customer_set_orders"]);

      // Customer Shop Orders
      case "CUSTOMER_GET_SHOP_ORDERS":
        return response;
    }
  } catch (err) {
    return null;
  }
};

// ----------------------------------------------------------------------------------------------------
const JWTAuthenticationManagerLogin = (SuccessHandle, FailedHandle) => {
  //console.log("TOKEN ILK HALI : =====> ", serviceState.token);
  try {
    var obj = {};
    obj.username = "test1";
    obj.password = "password1";
    $.ajax({
      url: "/api/Home/authenticate",
      type: "POST",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(obj),
      success: function (response) {
        if (response) {
          serviceState.token = response;
          console.log(
            "JWTAuthenticationManagerLogin response: ",
            serviceState.token
          );
          SuccessHandle();
          return;
        }
        FailedHandle();
      },
      failure: function (response) {
        console.error(response);
        FailedHandle();
      },
      error: function (response) {
        console.error(response);
        FailedHandle();
      },
    });
  } catch (exception) {
    console.error(exception);
    FailedHandle();
  }
};
// ----------------------------------------------------------------------------------------------------
const InitializationServer = (
  actions,
  value,
  SuccessOperation,
  FailedOperation,
  mediaUpload = false
) => {
  try {
    /////////////////////////////////////////////////////
    // Create an ajax post request to upload files. !! //
    /////////////////////////////////////////////////////
    if (mediaUpload) {
      $.ajax({
        type: "POST",
        url: actions,
        data: value,
        cache: false,
        contentType: false,
        processData: false,
        beforeSend: function (request) {
          var value = serviceState.token;
          if (value)
            request.setRequestHeader("Authorization", "Bearer " + value);
        },
        success: function (response) {
          SuccessOperation(response);
        },
        error: function (jqXHR) {
          if (jqXHR.status == 401) {
            // 401 Unauthorized ...
            JWTAuthenticationManagerLogin(
              () =>
                InitializationServer(
                  actions,
                  value,
                  SuccessOperation,
                  FailedOperation,
                  mediaUpload
                ),
              () =>
                FailedOperation({
                  Status: 0,
                  ErrMsg:
                    "401 Similar to 403 Forbidden, but specifically for use when authentication is possible but has failed or not yet been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource.",
                })
            );
            return;
          }
          FailedOperation({ Status: 0, ErrMsg: jqXHR });
        },
      });
      return;
    }
    /////////////////////////////////////////////////////
    $.ajax({
      url: GetEndPoint(actions),
      type: "POST",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(value),
      beforeSend: function (request) {
        var value = serviceState.token;
        if (value) {
          request.setRequestHeader("Authorization", "Bearer " + value);
        }
      },
      complete: function () {},
      success: function (response) {
        // 200 Success status code ...
        const result = GetParseData(actions, response);
        // result != null => "SuccessOperation" start method ...
        result
          ? SuccessOperation(result)
          : FailedOperation({
              Status: 0,
              ErrMsg: "Transaction did not occur.",
            });
        return;
      },
      error: function (jqXHR) {
        if (jqXHR.status == 401) {
          // 401 Unauthorized ...
          JWTAuthenticationManagerLogin(
            () =>
              InitializationServer(
                actions,
                value,
                SuccessOperation,
                FailedOperation
              ),
            () =>
              FailedOperation({
                Status: 0,
                ErrMsg:
                  "401 Similar to 403 Forbidden, but specifically for use when authentication is possible but has failed or not yet been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource.",
              })
          );
          return;
        }
        FailedOperation({
          Status: 0,
          ErrMsg: "The requested resource could not be found.",
        });
      },
    });
  } catch (err) {
    FailedOperation({ Status: 0, ErrMsg: err });
  }
};

export { Initialization, InitializationServer };
