import ls from "localstorage-slim";

// enable encryption globally
ls.config.encrypt = true;

// optionally use a different secret key
ls.config.secret = 89;

export const saveData_LOCAL_STORAGE = (a, b) => {
  return new Promise((myResolve, myReject) => {
    try {
      // save data in localStorage
      ls.set(a, b);
      myResolve();
    } catch (err) {
      // when error
      console.log(err);
      myReject(err);
    }
  });
};

export const getData_LOCAL_STORAGE = (a) => {
  return new Promise((myResolve, myReject) => {
    try {
      // calling get will deobfuscate data automatically ...
      const data = ls.get(a);
      myResolve(data);
    } catch (err) {
      // when error
      console.log(err);
      myReject(err);
    }
  });
};

export const deleteData_LOCAL_STORAGE = (a) => {
  return new Promise((myResolve, myReject) => {
    try {
      // delete data from the LS
      ls.remove(a); // returns undefined if successful, false otherwise ...
      myResolve();
    } catch (err) {
      // when error
      console.log(err);
      myReject(err);
    }
  });
};

export const clearData_LOCAL_STORAGE = () => {
  return new Promise((myResolve, myReject) => {
    try {
      // removes all data from the LS
      ls.clear(); // returns undefined if successful, false otherwise ...
      myResolve();
    } catch (err) {
      // when error
      console.log(err);
      myReject(err);
    }
  });
};
