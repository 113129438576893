import { Language, getLanguageCode } from "../../Actions";
import { LoginController } from "../../Controller/notlogin/login";
import { RegisterController } from "../../Controller/notlogin/register";
import { ForgotPasswordController } from "../../Controller/notlogin/forgotpasswordcreate";

function checkParamInUrl() {
  try {
    var url = new URL(window.location.href);
    var Searchword = url.searchParams.get("p");
    if (Searchword !== null && Searchword !== "") {
      Searchword = Searchword.toLowerCase();
      switch (Searchword) {
        case "education":
          return 1;
        case "hub":
          return 2;
        case "hubdetail":
          return 3;
        case "500":
          return 4;
        default:
          return 0;
      }
    } else {
      return -1;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}

let dataJson = null;

window.onload = function () {
  // init page ..
  // get language file ...
  getLanguageCode("loginorregister").then((language) => {
    Language(language).then(
      (langObj) => (
        (dataJson = Object.fromEntries(
          Object.entries(langObj).filter(([key]) =>
            key.startsWith("lg_variable")
          )
        )),
        LoginController(checkParamInUrl())
      )
    );
  });
};

(() => {
  $(".toggle-password").click(function () {
    $(this).toggleClass("bx-hide bx-show");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  var buttons = document.querySelectorAll(".formBtns");
  var divs = document.querySelectorAll(".login");

  buttons.forEach(function (button) {
    button.addEventListener("click", function () {
      // Tıklanan butonun hedef formun indeksini alın
      const targetkey = button.getAttribute("data-target");
      if (targetkey === "signup") {
        RegisterController(dataJson);
      } else if (targetkey === "recover") {
        ForgotPasswordController(dataJson);
      }

      var targetIndex = "form-" + targetkey;
      // Tüm formlardan 'active' sınıfını kaldırın
      divs.forEach(function (form) {
        form.classList.remove("active");
      });
      document.getElementById(targetIndex).classList.add("active");
    });
  });
})();
