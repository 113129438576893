export const Registered = "Registered";
export const AUTHORIZATION_BEARER = "AUTHORIZATION_BEARER";
export const EDITOR_FEATURES = "EDITOR_FEATURES";
export const USER_INFORMATION = "USER_INFORMATION";
export const TUTORIALS_CATEGORY = "TUTORIALS_CATEGORY";

// education-platform
export const EDUCATION_PLATFORM = "EDUCATION_PLATFORM";
export const EDUCATION_INSTRUCTOR_SELECTED_CLASS =
  "EDUCATION_INSTRUCTOR_SELECTED_CLASS";
export const NOTIFICATION_POPUP_MODAL = "NOTIFICATION_POPUP_MODAL";

// editor
export const SOUND_ENABLED = "SOUND_ENABLED";


// user session key
export const user = "dXNlciBpbmZvcm1hdGlvbg";