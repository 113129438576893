import ls from "localstorage-slim";
// enable encryption globally
ls.config.encrypt = true;
// optionally use a different secret key
ls.config.secret = 89;

export const Language = (dataJson, root = "lg_") => {
  return new Promise((myResolve, myReject) => {
    try {
      // Creating the regular expression
      let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;

      const keys = Object.keys(dataJson);
      const roots = root.split(",");

      let languagesKeys = [],
        languagesObject = {};
      ////////////////////////////////////////////
      for (let x = 0; x < roots.length; x++) {
        const element = roots[x];
        const result = keys.filter((e) => {
          return e.startsWith(element);
        });
        languagesKeys = languagesKeys.concat(result);
      }
      ////////////////////////////////////////////
      // find active page json...
      for (let index = 0; index < languagesKeys.length; index++) {
        ///////////////////////////
        const key = languagesKeys[index];
        const value = dataJson[key];
        languagesObject[key] = value;
        // check if the regular expression matches the string ...
        if (!key.startsWith("lg_variable") && !key.startsWith("lg_breadcrm")) {
          if (regexForHTML.test(value)) {
            // is a valid HTML
            document.getElementById(key).innerHTML = value;
          } else {
            // is not a valid HTML
            document.getElementById(key).innerText = value;
          }
          //console.log({
          //  container:document.getElementById(key),
          //  key: key
          //})
           //console.log(document.getElementById(key))
        }
        ///////////////////////////
      }
      ////////////////////////////////////////////
      myResolve(languagesObject);
      //
    } catch (err) {
      console.error(err);
      myReject();
    }
  });
};

const KEY = "LANGUAGE_CODE";
export const setLanguageCode = (langCode) => {
  return new Promise((myResolve, myReject) => {
    try {
      ls.set(KEY, langCode);
      myResolve();
    } catch (err) {
      console.error(err);
      myReject();
    }
  });
};

function defaultbyregion() {
  var timeZoneCityToCountry = require(`../languages/tz-cities-to-countries.json`);

  if (Intl) {
    var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tzArr = userTimeZone.split("/");
    var userRegion = tzArr[0];
    var userCity = tzArr[tzArr.length - 1];
    var userCountry = timeZoneCityToCountry[userCity];
    return userCountry;
  }
  return "";
}

export const getLanguageCountryCode = () => {
  return new Promise((myResolve, myReject) => {
    try {
      let lngCode = ls.get(KEY);

      if (!lngCode) {
        //if (defaultbyregion() === "Turkey") lngCode = "tr";
        //else lngCode = "en";
        lngCode = "en";
      }

      myResolve(lngCode);
    } catch (err) {
      console.error(err);
      myReject();
    }
  });
};

export const getLanguageCode = (page) => {
  return new Promise((myResolve, myReject) => {
    try {
      let lngCode = ls.get(KEY);
      let selectedLangCode = "";
      //
      const enCheck = document.getElementById("en_check_icon");
      const trCheck = document.getElementById("tr_check_icon");
      //
      if (!lngCode) {
        //if (defaultbyregion() === "Turkey") lngCode = "tr";
        //else lngCode = "en";
        lngCode = "en";
      }

      if (lngCode == "en") {
        selectedLangCode = "en_EN";
        enCheck ? (enCheck.style.display = "block") : null;
        trCheck ? (trCheck.style.display = "none") : null;
      } else {
        selectedLangCode = "tr_TR";
        enCheck ? (enCheck.style.display = "none") : null;
        trCheck ? (trCheck.style.display = "block") : null;
      }
      myResolve(require(`../languages/pages/${page}/${selectedLangCode}.json`));
    } catch (err) {
      console.error(err);
      myReject();
    }
  });
};

export const getLanguageCodePackByParameter = (page, parameter) => {
  return new Promise((myResolve, myReject) => {
    try {
      let lngCode = parameter;
      let selectedLangCode = "";
      //
      const enCheck = document.getElementById("en_check_icon");
      const trCheck = document.getElementById("tr_check_icon");
      //
      if (lngCode == "en") {
        selectedLangCode = "en_EN";
        enCheck ? (enCheck.style.display = "block") : null;
        trCheck ? (trCheck.style.display = "none") : null;
      } else {
        selectedLangCode = "tr_TR";
        enCheck ? (enCheck.style.display = "none") : null;
        trCheck ? (trCheck.style.display = "block") : null;
      }
      myResolve(require(`../languages/pages/${page}/${selectedLangCode}.json`));
    } catch (err) {
      console.error(err);
      myReject();
    }
  });
};

export const selectLanguage = (directionUrl = null) => {
  try {
    document.getElementById("tr_lang_click").onclick = function () {
      setLanguageCode("tr").then(() =>
        !directionUrl
          ? location.reload()
          : window.location.replace(directionUrl)
      );
    };
    document.getElementById("en_lang_click").onclick = function () {
      setLanguageCode("en").then(() =>
        !directionUrl
          ? location.reload()
          : window.location.replace(directionUrl)
      );
    };
  } catch (err) {
    console.error(err);
  }
};

export const selectLanguageBaxblocks = () => {
  try {
    var urlParams = new URLSearchParams(window.location.search);

    var cValue = urlParams.get("c");
    var pValue = urlParams.get("p");

    document.getElementById("tr_lang_click").onclick = function () {
      setLanguageCode("tr").then(() =>
        window.location.replace(
          `/bax-blocks?c=${cValue}&p=${pValue}&l=${"tr"}&t=${new Date().getTime()}`
        )
      );
    };
    document.getElementById("en_lang_click").onclick = function () {
      setLanguageCode("en").then(() =>
        window.location.replace(
          `/bax-blocks?c=${cValue}&p=${pValue}&l=${"en"}&t=${new Date().getTime()}`
        )
      );
    };
  } catch (err) {
    console.error(err);
  }
};
