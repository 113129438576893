export const NavigationActions = (key, object = {}) => {
  return new Promise((myResolve, myReject) => {
    try {
      switch (key) {
        case "INDEX":
          window.location.href = "/";
          myResolve();
          return;
        case "ROBOBAX_LOGIN":
          window.location.href = "/account/Login";
          myResolve();
          return;
        case "ROBOBAX_HOME":
          window.location.href = "/RobobaxHome";
          myResolve();
          return;
        case "ROBOBAX_PROJECT_LIST":
          //window.location.href = "/AllProjects?page=1";
          window.location.href = "/AllProjects";
          myResolve();
          return;
        case "ROBOBAX_SEARCH_PROJECT_LIST":
          window.location.href = "/AllProjects?search=" + object["SearchText"];
          myResolve();
          return;
        case "ROBOBAX_EDITOR":
          window.location.href = "/RobobaxEditor";
          myResolve();
          return;
        case "ROBOBAX_EXAMPLES":
          window.location.replace(
            `/Examples?p=${object["p"]}&o=${object["o"]}&category=${object["category"]}`
          );
          myResolve();
          return;
        case "ROBOBAX_SEARCH_EXAMPLES":
          window.location.href = "/Examples?search=" + object["SearchText"];
          myResolve();
          return;
        case "ROBOBAX_PLUGIN_WELCOME":
          if (!object["p"] && !object["o"]) {
            window.location.href = `/getting-started/plugin/welcome?s=${object["s"]}`;
          } else {
            window.location.href = `/getting-started/plugin/welcome?p=${object["p"]}&o=${object["o"]}`;
          }
          myResolve();
          return;
        case "ROBOBAX_PLUGIN_DOWNLOAD":
          window.location.href = "/getting-started/plugin/download";
          myResolve();
          return;
        case "ROBOBAX_PLUGIN_INSTALL":
          window.location.href = "/getting-started/plugin/install";
          myResolve();
          return;
        case "ROBOBAX_PLUGIN_CONGRATULATIONS":
          window.location.href = "/getting-started/plugin/congratulations";
          myResolve();
          return;
        case "ROBOBAX_FEEDBACK":
          window.location.href = "/Feedback";
          myResolve();
        case "ROBOBAX_SHOP":
          window.location.replace("/shop");
          myResolve();
          return;
      }
    } catch (err) {
      // when error
      console.log(err);
      myReject(err);
    }
  });
};

export const NavigationCheck = (BlockCallback, AllowCallback) => {
  var path = window.location.pathname;
  var page = path.split("/").pop();

  if (page.startsWith("RobobaxHome"))
    AllowCallback && AllowCallback("RobobaxHome");
  else if (page.startsWith("RobobaxEditor")) BlockCallback && BlockCallback();
  else if (page.startsWith("AllProjects")) BlockCallback && BlockCallback();
  else if (page.startsWith("Examples")) BlockCallback && BlockCallback();
  else if (page.startsWith("MyTutorialDetail"))
    BlockCallback && BlockCallback();
  else if (page.startsWith("MyTutorialList")) BlockCallback && BlockCallback();
  else if (page.startsWith("Videos")) BlockCallback && BlockCallback();
  else if (page.startsWith("Feedback")) BlockCallback && BlockCallback();
  else AllowCallback && AllowCallback();
};
