import { Initialization } from "./ServiceAction";
// Robobax Agent Is Running Status ...
function robobaxSpinnerShow(loading = true) {
  // Open spinner ...
  return new Promise(async (myResolve, myReject) => {
    try {
      if (loading) {
        var a = __.robobaxSpinnerDom;
        a.style.display = "block";
      }
      myResolve(); // when successful
    } catch (err) {
      console.log(err);
      myReject(err); // when error
    }
  });
}

function robobaxSpinnerHide(loading = true) {
  // Hide spinner ...
  return new Promise(async (myResolve, myReject) => {
    try {
      if (loading) {
        var b = __.robobaxSpinnerDom;
        b.style.display = "none";
      }
      myResolve(); // when successful
    } catch (err) {
      console.log(err);
      myReject(err); // when error
    }
  });
}

function robobaxAgentInformation() {
  return new Promise(async (myResolve, myReject) => {
    try {
      //var a = __.robobaxAgentInformationDom;
      //a.style.display = "block";

      const connect_ = document.getElementById("BtnConnect");
      if (connect_) {
        if (connect_.style.display != "none") {
          $("#robobaxAgentInformationPop").modal({
            backdrop: "static",
            keyboard: false,
          });
          await $("#robobaxAgentInformationPop").modal("show");
        }
      }

      myResolve(); // when successful
    } catch (err) {
      console.log(err);
      myReject(err); // when error
    }
  });
}

function robobaxAgentCompilerLoaderShow(a) {
  return new Promise(async (myResolve, myReject) => {
    try {
      var uploadmodalDOM = document.getElementById("uploadmodal");
      uploadmodalDOM.innerHTML = a
        ? // compiler modal ...
          `<div class="modal-dialog modal-dialog-centered" style="max-width: 400px">
                <div class="modal-content">
                    <div class="modal-header radialgradient-success">
                        <div class="d-flex justify-content-center w-100">
                            <h5 class="modal-title" id="uploadmodalTitle">Building...</h5>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex justify-content-center mb-3 mt-3" style="overflow:hidden;position:relative">
                            <div class="loader2">
                            </div>
                            <div id="loadProgressText" class="load-text" style="font-size: 14px;">Building 0%</div>
                        </div>
                    </div>
                </div>
             </div>
            `
        : // upload modal ...
          `<div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
                <div class="modal-content">
                    <div class="modal-header radialgradient-success">
                        <div class="d-flex justify-content-center w-100">
                            <h5 class="modal-title" id="exampleModalLabel">Uploading</h5>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex justify-content-center mb-3 mt-3" style="overflow:hidden;position:relative">
                            <div class="loader2">
                            </div>
                            <div class="load-text" style="font-size: 14px;">Uploading</div>
                        </div>
                    </div>
                </div>
             </div>
            `;
      //
      $("#uploadmodal").modal({ backdrop: "static", keyboard: false });
      await $("#uploadmodal").modal("show");
      myResolve(); // when successful
    } catch (err) {
      console.log(err);
      myReject(err); // when error
    }
  });
}

function robobaxAgentCompilerLoaderHide() {
  return new Promise((myResolve, myReject) => {
    try {
      setTimeout(async function () {
        await $("#uploadmodal").modal("hide");
      }, 500);
      myResolve(); // when successful
    } catch (err) {
      console.log(err);
      myReject(err); // when error
    }
  });
}

export const RobobaxAgentIsRunning = (SuccessHandle, a = true) => {
  robobaxSpinnerShow().then(() => {
    /* code if successful */
    Initialization(
      {
        Param1: "SVNSVU5OSU5H",
      },
      (res) => (a ? SuccessHandle(res) : robobaxSpinnerHide()),
      () => {
        // Failed handle ...
        robobaxSpinnerHide().then(() => {
          /* code if successful */
          if (a) {
            $("#installmodal").modal({ backdrop: "static", keyboard: false });
            $("#installmodal").modal("show");
          } else {
            robobaxAgentInformation();
          }
        });
      }
    );
  });
};

export const RobobaxAgentGetDeviceList = (SuccessHandle, FailedHandle) => {
  Initialization(
    {
      Param1: "REVWSUNFX0xJU1Q=",
    },
    (data) => {
      robobaxSpinnerHide().then(() => {
        SuccessHandle(data);
      });
    },
    () => {
      robobaxSpinnerHide().then(() => {
        FailedHandle();
      });
    }
  );
};

export const RobobaxAgentConnectDevice = (
  PortName,
  SuccessHandle,
  FailedHandle,
  baudRate = "9600"
) => {
  Initialization(
    {
      Param1: "REVWSUNFX0NPTk5FQ1RJT04=",
      Param2: PortName + "," + baudRate,
    },
    (data) => {
      robobaxSpinnerHide().then(() => {
        SuccessHandle(data);
      });
    },
    () => {
      robobaxSpinnerHide().then(() => {
        FailedHandle();
      });
    }
  );
};

export const RobobaxAgentDisConnectDevice = (SuccessHandle, FailedHandle) => {
  Initialization(
    {
      Param1: "REVWSUNFX0RJU0NPTk5FQ1Q=",
    },
    (data) => {
      robobaxSpinnerHide().then(() => {
        SuccessHandle(data);
      });
    },
    () => {
      robobaxSpinnerHide().then(() => {
        FailedHandle();
      });
    }
  );
};

export const RobobaxAgentConnectDeviceStatus = (
  SuccessHandle,
  FailedHandle
) => {
  Initialization(
    {
      Param1: "REVWSUNFX1NUQVRVUw==",
    },
    SuccessHandle,
    FailedHandle
  );
};

export const RobobaxAgentSerialMonitorOpen = (SuccessHandle, FailedHandle) => {
  robobaxSpinnerShow().then(() => {
    /* code if successful */
    Initialization(
      {
        Param1: "REVWSUNFX1NFUklBTF9NT05JVE9SX09QRU4=",
      },
      (data) => {
        robobaxSpinnerHide().then(() => {
          SuccessHandle(data);
        });
      },
      () => {
        robobaxSpinnerHide().then(() => {
          FailedHandle();
        });
      }
    );
  });
};

export const RobobaxAgentSerialMonitorClose = (SuccessHandle, FailedHandle) => {
  robobaxSpinnerShow().then(() => {
    /* code if successful */
    Initialization(
      {
        Param1: "REVWSUNFX1NFUklBTF9NT05JVE9SX0NMT1NF",
      },
      (data) => {
        robobaxSpinnerHide().then(() => {
          SuccessHandle(data);
        });
      },
      () => {
        robobaxSpinnerHide().then(() => {
          FailedHandle();
        });
      }
    );
  });
};

export const RobobaxAgentSerialMonitorBrinkData = (
  location,
  SuccessHandle,
  FailedHandle
) => {
  Initialization(
    {
      Param1: "REVWSUNFX1NFUklBTF9NT05JVE9SX0JSSU5HX0RBVEE=",
      Param2: location,
    },
    (data) => {
      robobaxSpinnerHide(false).then(() => {
        SuccessHandle(data);
      });
    },
    () => {
      robobaxSpinnerHide(false).then(() => {
        FailedHandle();
      });
    }
  );
};

export const RobobaxAgentSerialMonitorSendingData = (
  value,
  SuccessHandle,
  FailedHandle
) => {
  robobaxSpinnerShow().then(() => {
    /* code if successful */
    Initialization(
      {
        Param1: "REVWSUNFX1NFUklBTF9NT05JVE9SX1NFTkRfREFUQQ==",
        Param2: value,
      },
      (data) => {
        robobaxSpinnerHide().then(() => {
          SuccessHandle(data);
        });
      },
      () => {
        robobaxSpinnerHide().then(() => {
          FailedHandle();
        });
      }
    );
  });
};

export const RobobaxAgentDetailsDevice = (
  PortName,
  SuccessHandle,
  FailedHandle
) => {
  Initialization(
    {
      Param1: "REVWSUNFX0RFVEFJTFM=",
      Param2: PortName,
    },
    (data) => {
      const { Status } = data;
      if (Status == "1") {
        SuccessHandle(data);
        return;
      }
      FailedHandle();
    },
    () => {
      FailedHandle();
    }
  );
};

export const RobobaxAgentOpenSerialMonitorCLI = (
  SuccessHandle,
  FailedHandle
) => {
  Initialization(
    {
      Param1: "U0VSSUFMX01PTklUT1I=",
    },
    (data) => {
      const { Status } = data;
      if (Status == "1") {
        SuccessHandle(data);
        return;
      }
      FailedHandle();
    },
    () => {
      FailedHandle();
    }
  );
};

export const RobobaxAgentSerialMonitorIsWorking = (
  notworking,
  working = null,
  failed = null
) => {
  Initialization(
    {
      Param1: "U0VSSUFMX01PTklUT1JfU1RBVFVT",
    },
    (data) => {
      const { Status } = data;
      console.log("SerialMonitor Is Working: ", data);
      if (Status == "0") {
        notworking(data);
        return;
      }
      working && working();
    },
    () => failed && failed()
  );
};

export const RobobaxAgentCompilerDevice = (
  Object_,
  SuccessHandle,
  FailedHandle
) => {
  robobaxAgentCompilerLoaderShow(true).then(() => {
    Initialization(
      {
        Param1: "REVWSUNFX0NPTVBJTEVS",
        Param2: Object_["ArduinoCode"],
        AvrName: Object_["AvrName"],
      },
      (data) => {
        const { Status } = data;
        if (Status == "1") {
          SuccessHandle(data);
          return;
        }
        robobaxAgentCompilerLoaderHide().then(SuccessHandle(data));
      },
      () => {
        robobaxAgentCompilerLoaderHide().then(() => {
          FailedHandle();
        });
      },
      true // Progress status = true ...
    );
  });
};

export const RobobaxAgentUploadDevice = (
  Object_,
  SuccessHandle,
  FailedHandle
) => {
  robobaxAgentCompilerLoaderShow(false).then(() => {
    Initialization(
      {
        Param1: "REVWSUNFX1VQTE9BRA==",
        Param2: Object_["PortName"],
        AvrName: Object_["AvrName"],
      },
      (data) => {
        robobaxAgentCompilerLoaderHide().then(SuccessHandle(data));
      },
      () => {
        robobaxAgentCompilerLoaderHide().then(FailedHandle);
      }
    );
  });
};

export const RobobaxAgentFactoryResetDevice = (
  Object_,
  SuccessHandle,
  FailedHandle
) => {
  robobaxAgentCompilerLoaderShow(false).then(() => {
    Initialization(
      {
        Param1: "REVWSUNFX0ZBQ1RPUllfUkVTRVQ=",
        Param2: Object_["PortName"],
        AvrName: Object_["AvrName"],
      },
      (data) => {
        robobaxAgentCompilerLoaderHide().then(SuccessHandle(data));
      },
      () => {
        robobaxAgentCompilerLoaderHide().then(FailedHandle);
      }
    );
  });
};

export const RobobaxAgentAddLibrary = (SuccessHandle, FailedHandle) => {
  robobaxSpinnerShow().then(() => {
    /* code if successful */
    Initialization(
      {
        Param1: "REVWSUNFX0FERF9MSUJSQVJZ",
      },
      (data) => {
        robobaxSpinnerHide().then(() => {
          SuccessHandle(data);
        });
      },
      () => {
        robobaxSpinnerHide().then(() => {
          FailedHandle();
        });
      }
    );
  });
};
