import {
  ToastifyShow,
  getData_LOCAL_STORAGE,
  saveData_LOCAL_STORAGE,
  deleteData_LOCAL_STORAGE,
} from "../../Actions";
import { Registered, user } from "../../Actions/Types";
var ubid = require("ubid");

function ToastifyShowRun(type = "", text = "", callback = null) {
  ToastifyShow(type, text, callback);
}

let captchaContainer = document.getElementById("login-captcha");
let captchaReload = document.getElementById("login-reload-captcha");

let usernameInput = document.getElementById("login-username-input");
let PasswordInput = document.getElementById("login-password-input");
let CaptchaInput = document.getElementById("login-captcha-input");
let loginbtn = document.getElementById("login-btn");

const Login = {
  /////////////////////////////////////////////////////////////////
  init: function () {
    document.getElementById("login-main").style.display = "flex";
    document.getElementById("robobaxSpinner").style.display = "block";

    Login.createCaptcha();
    /*---start enter login---*/
    document.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        Login.login();
      }
    });

    loginbtn.onclick = function () {
      Login.login();
    };

    captchaReload.onclick = function () {
      Login.createCaptcha();
    };

    Login.defaultStorage();
  },
  /////////////////////////////////////////////////////////////////
  defaultStorage: function (callback = null) {
    const p1 = deleteData_LOCAL_STORAGE(user);
    const p2 = deleteData_LOCAL_STORAGE(Registered);

    Promise.all([p1, p2])
      .then(() => callback && callback())
      .catch((err) => console.error(err));
  },
  /////////////////////////////////////////////////////////////////
  decryptData: function (encryptedData) {
    encryptedData = atob(encryptedData);
    return encryptedData.split("").reverse().join("");
  },
  /////////////////////////////////////////////////////////////////
  createCaptcha: function () {
    //show
    CaptchaInput.value = "";
    document.getElementById("login-captcha-container").style.display = "flex";
    //clear the contents of captcha div first
    captchaContainer.innerHTML = null;

    fetch("/api/Home/captcha")
      .then((response) => response.text())
      .then((base64String) => {
        document.getElementById("robobaxSpinner").style.display = "none";

        const imgUrl = `data:image/png;base64, ${base64String}`;
        const imgElement = document.createElement("img");
        imgElement.src = imgUrl;
        captchaContainer.appendChild(imgElement);
      })
      .catch((error) => console.error(error));
  },
  /////////////////////////////////////////////////////////////////
  login: async function () {
    var username = usernameInput.value;
    var password = PasswordInput.value;
    var captcha = CaptchaInput.value;

    if (!username.replace(/\s+/g, "") || !password.replace(/\s+/g, "")) {
      Login.WrongEmailOrPassword();
      return;
    }

    if (!captcha.replace(/\s+/g, "")) {
      Login.WrongCaptcha();
      return;
    }

    Functions.Field.username = username;
    Functions.Field.password = password;
    Functions.Field.captchacode = captcha;

    Login.getBrowserSignatureData().then((object) =>
      Login.performLoginProccess(object)
    );
  },
  /////////////////////////////////////////////////////////////////
  performLoginProccess: function (signatureData) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-From-Login", "true");
    if (signatureData) {
      headers.append("EVXpSJzHy820Xjc", signatureData["browser"]["signature"]);
      headers.append("zLVVzvv8P3nddES", signatureData["canvas"]["signature"]);
      headers.append("50wnO6on7rMlbNJ", signatureData["random"]["signature"]);
    }

    document.getElementById("robobaxSpinner").style.display = "block";

    fetch("/api/Home/login", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        Username: Functions.Field.username,
        Password: Functions.Field.password,
        Captcha: Functions.Field.captchacode,
        IpAddress: "",
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          return data;
        } else {
          throw new Error(JSON.stringify(data));
        }
      })
      .then((res) => {
        const info = res.info;
        const userInfo = JSON.parse(res.data.value[0]["user_notlogin"]);
        const data = {
          ...userInfo["FoundUser"][0],
        };

        const servicesOptions = saveData_LOCAL_STORAGE(Registered, info);
        const customerOptions = saveData_LOCAL_STORAGE(
          user,
          JSON.stringify(data)
        );

        Promise.all([servicesOptions, customerOptions])
          .then(() =>
            window.location.replace(
              Functions.Field.IsUrlRedirection === 1 ? "/?p=education" : "/"
            )
          )
          .catch((err) => {
            console.error("GetOptionsManagement error: ", err.message);
            ToastifyShowRun("topwarning", err.message);
          });
      })
      .catch((errorObject) => {
        Functions.Field.failedLoginCount += 1;
        console.error("Error: ", errorObject.message);

        Login.Unauthorized(errorObject)
          ? Login.WrongCaptcha()
          : Login.WrongEmailOrPassword();

        Login.createCaptcha();
      });
  },
  /////////////////////////////////////////////////////////////////
  WrongEmailOrPassword: function () {
    const alert = document.getElementById("login-alert-warning");
    document.getElementById("logincaptcha-alert-warning").style.display =
      "none";
    alert.style.display = "block";
  },
  /////////////////////////////////////////////////////////////////
  WrongCaptcha: function () {
    const alert = document.getElementById("logincaptcha-alert-warning");
    document.getElementById("login-alert-warning").style.display = "none";
    alert.style.display = "block";
  },
  /////////////////////////////////////////////////////////////////
  Unauthorized: function (errorObject, key = "captchawrong") {
    try {
      return JSON.parse(
        JSON.parse(errorObject.message).data.value
      ).Msg.includes(key);
    } catch (err) {
      return false;
    }
  },
  /////////////////////////////////////////////////////////////////
  getBrowserSignatureData: function () {
    return new Promise((myResolve) => {
      if (!ubid) {
        myResolve(null);
      }
      ubid.get(function (error, signatureData) {
        if (error) {
          console.error(error);
          myResolve(null);
          return;
        }
        myResolve(signatureData);
      });
    });
  },
};

const Functions = {
  Field: {
    failedLoginCount: 0,
    username: "",
    password: "",
    captchacode: "",
    IsUrlRedirection: -1,
  },
  getLangCodeFromUrl: function () {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("lang") || "en"; // takes the 'lang' parameter, otherwise returns 'en'
  },
  CheckCustomerManagement: function () {
    return new Promise((myResolve, myReject) => {
      getData_LOCAL_STORAGE(user)
        .then((item) => {
          if (!item) {
            myReject(null);
            return;
          }
          const data = JSON.parse(item);
          myResolve(data);
        })
        .catch((err) => {
          console.error("CustomerManagement error: ", err.message);
          myReject(err.message);
        });
    });
  },
  PreviouslyLoggedIn: function () {
    switch (Functions.Field.IsUrlRedirection) {
      case 1: // "education"
        window.location.replace(
          `/platform/Institutions?t=${new Date().getTime()}`
        );
        break;

      case 2: // "hub"
        window.location.replace(
          `/hub?pageindex=1&lang=${Functions.getLangCodeFromUrl()}&t=${new Date().getTime()}`
        );
        break;

      case 3: // "hubdetail":
        window.location.replace(
          `/hub/hubDetails?pageindex=${new URLSearchParams(
            window.location.search
          ).get("pageindex")}&t=${new Date().getTime()}`
        );
        break;

      case 4: // "500" error:
        Login.init();
        break;

      default: // "dashboard"
        window.location.replace(
          `/platform/Dashboard?t=${new Date().getTime()}`
        );
        break;
    }
  },
  LoginInit: function () {
    switch (Functions.Field.IsUrlRedirection) {
      case 2: // "hub"
        window.location.replace(
          `/hub?pageindex=1&lang=${Functions.getLangCodeFromUrl()}&t=${new Date().getTime()}`
        );
        break;

      case 3: // "hubdetail":
        window.location.replace(
          `/hub/hubDetails?pageindex=${new URLSearchParams(
            window.location.search
          ).get("pageindex")}&t=${new Date().getTime()}`
        );
        break;

      default:
        Login.init();
        break;
    }
  },
};

const LoginController = (directionUrl) => {
  Functions.Field.IsUrlRedirection = directionUrl;
  Functions.CheckCustomerManagement()
    .then((data) => Functions.PreviouslyLoggedIn(data))
    .catch((err) =>
      err ? ToastifyShowRun("topwarning", err) : Functions.LoginInit()
    );
};

export { LoginController };
