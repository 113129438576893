const MessageBox = require("windowsmessagebox");

/**
 * Type defines the icon of the message box type:"Information Message"
 * @param {*} title
 * @param {*} message
 * @param {*} buttons
 * @returns
 */
export const MessageBox_Info = (title, message, buttons = [["Ok", true]]) => {
  return MessageBox.show(title, message, "info", buttons, "center");
};

/**
 * Type defines the icon of the message box type:"Warning Message"
 * @param {*} title
 * @param {*} message
 * @param {*} buttons
 * @returns
 */
export const MessageBox_Warning = (
  title,
  message,
  buttons = [["Ok", true]]
) => {
  return MessageBox.show(title, message, "warning", buttons, "center");
};

/**
 * Type defines the icon of the message box type:"Error Message"
 * @param {*} title
 * @param {*} message
 * @param {*} buttons
 * @returns
 */
export const MessageBox_Error = (title, message, buttons = [["Ok", true]]) => {
  return MessageBox.show(title, message, "error", buttons, "center");
};
