import jCaptcha from 'js-captcha';

// optionally, set maximum number of captcha validation on event:
const maxNumberOfTries = 5;
export const js_captchaRunTime = (responseSuccess, responseError, numberOfTriesCallback) => {
    return new jCaptcha({
        el: '.jCaptcha',
        canvasClass: 'jCaptchaCanvas',
        canvasStyle: {
            // required properties for captcha stylings:
            width:  100,
            height: 15,
            textBaseline: 'top',
            font: '21px Roboto',
            textAlign: 'left',
            fillStyle: '#1c1c1c'
        },
        // set callback function for success and error messages:
        callback: (response, $captchaInputElement, numberOfTries) => {
            if (response == 'success') {
                // success handle, e.g. continue with form submit
                              
                responseSuccess();
            }
            if (response == 'error') {
                // error handle, e.g. add error class to captcha input
                responseError();
                if (numberOfTries === maxNumberOfTries) {
                    // maximum attempts handle, e.g. disable form
                    numberOfTriesCallback();
                }
            }
        }
    });
};